import React, { FunctionComponent } from 'react';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import { compact, filter, isEqual, map, uniqBy } from 'lodash';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';

import { Dropdown, ModelInstance, RowIdentifier } from '../../types/models';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IInputsMultiSelectProps {
  /**
   * List of inputs to be populated
   */
  inputsList: RowIdentifier[];
  /**
   * List of inputs selected
   */
  preSelectedInputs?: RowIdentifier[];
  /**
   * The onChange handler
   * @param input
   */
  handleInputChange: (input: RowIdentifier[]) => void;
  /**
   * The model
   * This component will be disabled until a model is passed in
   */
  modelInstance?: ModelInstance;
  size?: 'small' | 'medium';
  hasError?: boolean;
  selectAll?: boolean;
}

const InputsMultiSelect: FunctionComponent<IInputsMultiSelectProps> = ({
  inputsList,
  preSelectedInputs,
  handleInputChange,
  modelInstance,
  size = 'small',
  selectAll = false,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const getSelectAllOption = () => {
    return {
      Name: Dropdown.Select_All,
      DimNumber: null,
      DisableInputEdit: false,
      DisableOutputAggregation: false,
      FormatKey: 0,
      GroupID: undefined,
      InputCategory: '',
      ModelInstanceID: modelInstance?.id || 0,
      PreviousGroupName: null,
      TabName: '',
      Type: 'Input' as const,
      created_at: '',
      id: 999999,
      value: 9999999,
    };
  };

  const filterOptions = (
    options: RowIdentifier[],
    { inputValue }: { inputValue: string }
  ) => {
    if (inputValue === '') {
      return options;
    }
    return options.filter((option) =>
      option.Name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: RowIdentifier[]
  ) => {
    const target = event.target as HTMLElement;
    const isSelectAllClick =
      target.textContent === Dropdown.Select_All ||
      target.closest('li')?.textContent === Dropdown.Select_All;

    if (
      isSelectAllClick &&
      preSelectedInputs?.length !== inputsList.length &&
      preSelectedInputs?.length !==
        filterOptions(inputsList, { inputValue: searchTerm }).length
    ) {
      let filteredInputs = filterOptions(inputsList, {
        inputValue: searchTerm,
      });
      let options = [];
      if (preSelectedInputs && searchTerm !== '') {
        options = uniqBy([...preSelectedInputs, ...filteredInputs], 'Name');
        handleInputChange(options);
      } else {
        options = uniqBy([...filteredInputs], 'Name');
        handleInputChange(filteredInputs);
      }
      setSearchTerm('');
    } else if (
      isSelectAllClick &&
      preSelectedInputs?.length === inputsList.length
    ) {
      handleInputChange([]);
    } else {
      let newValue = filter(value, (v) => v.Name !== Dropdown.Select_All);
      handleInputChange(newValue);
    }
  };

  const getSelectedFlag = (selected: boolean) => {
    if (preSelectedInputs?.length === inputsList.length) {
      return true;
    } else {
      return selected;
    }
  };

  const setSearchString = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const searchString = target.value;
    setSearchTerm(searchString);
  };

  return (
    <Autocomplete
      multiple
      ChipProps={{
        size: 'small',
      }}
      value={preSelectedInputs}
      onChange={(event, value) => handleChange(event, value)}
      disableCloseOnSelect
      options={inputsList || []}
      getOptionLabel={(option) => option.Name}
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      filterOptions={(filteredOptions, params) => {
        const filtered = filterOptions(filteredOptions, params);
        let selectAllOption = null;
        if (selectAll && filtered.length > 0) {
          selectAllOption = getSelectAllOption();
        }

        return compact([selectAllOption, ...filtered]) as RowIdentifier[];
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.Name, inputValue, {
          insideWords: true,
          findAllOccurrences: true,
        });
        const parts = parse(option.Name, matches);
        return (
          <Typography component="li" {...props} noWrap>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selectAll ? getSelectedFlag(selected) : selected}
            />
            {map(parts, (part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                  whiteSpace: 'pre', // Preserve spaces
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        );
      }}
      disabled={modelInstance === undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select inputs"
          fullWidth
          size={size}
          onInput={setSearchString}
        />
      )}
    />
  );
};

export default InputsMultiSelect;
