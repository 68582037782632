import React, { PureComponent } from 'react';
import { format } from 'date-fns';
import { visuallyHidden } from '@mui/utils';
import { includes } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  AccountTree,
  CheckCircle,
  Lock,
  LockOpen,
  Merge,
  MoreHoriz,
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { errorMessage } from 'src/utils/StaticErrorMessages';

import * as API from '../../services/API';
import { HTTPError } from '../../support/errors';
import { Submission, SubmissionStatus } from '../../types/models';

import APICacheContext from '../shared/APICacheContext';

import Flex from '../shared/Flex';

import StyledMenu from '../shared/StyledMenu';

import APICache from '../../services/APICache';

import LinearProgressBar from '../shared/LinearProgressBar';

import UserLabel from './UserLabel';
import ThDerivedIcon from './ScenarioDetails/ThDerivedIcon';

interface Props {
  instanceId: number;
  submission: Submission;
  active: boolean;
  comparing: boolean;
  canSubmit: boolean;
  isActionRestricted: boolean;
  dimensionGroup?: string;
  onDelete: () => void;
  onExpand: (submissionId: number, expanded: boolean) => void;
  expanded: boolean;
  onRestore: (submission: Submission) => void;
  onSubmissionTransferTrigger: (submission: Submission) => void;
  onLockSubmission: (submission: Submission) => void;
  onCompare: (submission: Submission) => void;
  canCompare: boolean;
  onLaunchComparison: () => void;
  canLaunchComparison: boolean;
}

interface State {
  activationConfirming: boolean;
  activating: boolean;
  activationError: HTTPError | null;
}

const INITIAL_STATE: State = {
  activationConfirming: false,
  activating: false,
  activationError: null,
};

class SubmissionCard extends PureComponent<Props, State> {
  public static contextType = APICacheContext;
  public state: State = INITIAL_STATE;

  public render() {
    const { active, submission, expanded, dimensionGroup } = this.props;

    return (
      <SmallCard
        sx={{
          backgroundColor: (t) => (!active ? '#F5F5F5' : undefined),
        }}
      >
        <Flex alignItems="center">
          <Box mr={2}>
            <UserLabel
              color={(t) => (!active ? t.palette.common.muted : undefined)}
              user={submission.User}
            />
            <Typography
              variant="caption"
              component="div"
              color={(t) => t.palette.common.muted}
            >
              Created{' '}
              {format(new Date(submission.created_at), 'MMM. d, yyyy hh:mma')}
            </Typography>
          </Box>
          <Flex flex="auto" justifyContent="flex-end">
            {submission.IsLocked && (
              <Chip
                color="secondary"
                label="LOCKED"
                size="small"
                style={{ marginRight: 12 }}
              />
            )}
            {active && <Chip color="secondary" label="ACTIVE" size="small" />}
            {this.renderStatus()}
          </Flex>
          <Box mx={2}>
            <Typography color={(t) => t.palette.common.muted}>
              {dimensionGroup}
            </Typography>
          </Box>
          <IconButton
            onClick={this.handleClick}
            title={expanded ? 'Collapse' : 'Expand'}
            size="small"
          >
            {expanded ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
        </Flex>
        {expanded && this.renderBody()}
      </SmallCard>
    );
  }

  public renderStatus() {
    const { submission, comparing } = this.props;

    switch (submission.Status) {
      case SubmissionStatus.Finished:
        return comparing ? (
          <Chip
            label="COMPARE"
            color="success"
            sx={{
              ml: 1,
            }}
            size="small"
          />
        ) : null;
      case SubmissionStatus.Failed:
        return <Chip color="error" label="FAILED" size="small" />;
      case SubmissionStatus.NoDataReturned:
        return <Chip color="warning" label="NO DATA RETURNED" size="small" />;
      case SubmissionStatus.FailedWithReturnFile:
        return <Chip color="error" label="FAILED" size="small" />;
      case SubmissionStatus.Queued:
      case SubmissionStatus.Started:
        return <Chip label="PROCESSING" size="small" />;
      // no default
    }
  }

  public renderBody() {
    const {
      active,
      canSubmit,
      isActionRestricted,
      comparing,
      submission,
      canCompare,
      onRestore,
      onSubmissionTransferTrigger,
      onLockSubmission,
      onCompare,
      onDelete,
      onLaunchComparison,
      canLaunchComparison,
    } = this.props;
    const { activating, activationConfirming, activationError } = this.state;

    return (
      <Box>
        <Dialog open={activationConfirming} onClose={this.cancelSetActive}>
          <DialogTitle>
            This will create a new submission with the same data as this
            version.
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.cancelSetActive} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={this.confirmSetActive}
              autoFocus
              variant="contained"
            >
              Activate
            </Button>
          </DialogActions>
        </Dialog>
        <Box mb={2}>
          <Typography variant="caption">
            Version: {submission.version}
          </Typography>
        </Box>
        {includes(
          [
            SubmissionStatus.Failed,
            SubmissionStatus.FailedWithReturnFile,
            SubmissionStatus.NoDataReturned,
          ],
          submission.Status
        ) && (
          <Box mb={2}>
            <Alert severity="error" icon={false}>
              <AlertTitle>Processing failed</AlertTitle>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid md={9}>
                  {submission.Status_Message &&
                  submission.Status_Message.includes('InputRowID') &&
                  JSON.parse(submission.Status_Message).length > 0
                    ? errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE
                    : submission.Status_Message}
                </Grid>
                <Grid md={3} container justifyContent="flex-end">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={onDelete}
                    size="small"
                  >
                    Delete Submission
                  </Button>
                </Grid>
              </Grid>
            </Alert>
          </Box>
        )}
        {includes([SubmissionStatus.NoDataReturned], submission.Status) && (
          <Box mb={2}>
            <Alert severity="warning" icon={false}>
              <AlertTitle>Processing failed</AlertTitle>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid md={9}>{submission.Status_Message}</Grid>
                <Grid md={3} container justifyContent="flex-end">
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={onDelete}
                    size="small"
                  >
                    Delete Submission
                  </Button>
                </Grid>
              </Grid>
            </Alert>
          </Box>
        )}
        {activating && (
          <Box mb={2}>
            <LinearProgressBar variant="indeterminate" />
          </Box>
        )}
        {activationError !== null && (
          <Box mb={2}>
            <Alert severity="error" icon={false}>
              {activationError?.body?.message ? (
                <Box>
                  <AlertTitle>Error</AlertTitle>
                  {activationError.body.message}
                </Box>
              ) : (
                <Box>
                  <AlertTitle>Something went wrong</AlertTitle>Unable to
                  activate, check your connection and try again.
                </Box>
              )}
            </Alert>
          </Box>
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid sm={10}>
            {submission.Comment ? (
              <Typography
                color={(t) => t.palette.common.muted}
                sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
              >
                {submission.Comment}
              </Typography>
            ) : (
              <Typography color={(t) => t.palette.common.disabled}>
                No comment
              </Typography>
            )}
          </Grid>
          <Grid container justifyContent="flex-end" sm={1}>
            <PopupState variant="popover" popupId="demoMenu">
              {(popupState) => (
                <>
                  <IconButton
                    {...bindTrigger(popupState)}
                    disabled={submission.Status === SubmissionStatus.Failed}
                  >
                    <MoreHoriz />
                  </IconButton>
                  <StyledMenu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={() => {
                        this.handleSetActive();
                        popupState.close();
                      }}
                      disabled={
                        activating ||
                        submission.Status !== SubmissionStatus.Finished ||
                        active ||
                        !canSubmit
                      }
                    >
                      <ListItemIcon>
                        <ThDerivedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Set as Active</ListItemText>
                      <Typography variant="body2">
                        {active ? (
                          <>
                            <CheckCircle color="success" />
                            <Box component="span" sx={visuallyHidden}>
                              Active version
                            </Box>
                          </>
                        ) : null}
                      </Typography>
                    </MenuItem>
                    {!(
                      submission.Status !== SubmissionStatus.Finished ||
                      !active ||
                      (this.props.dimensionGroup ?? '').length <= 0
                    ) && (
                      <MenuItem
                        onClick={() => {
                          onLockSubmission(submission);
                          popupState.close();
                        }}
                        disabled={
                          submission.Status !== SubmissionStatus.Finished ||
                          !active ||
                          !canSubmit
                        }
                      >
                        <ListItemIcon>
                          {submission.IsLocked ? (
                            <LockOpen fontSize="small" />
                          ) : (
                            <Lock fontSize="small" />
                          )}
                        </ListItemIcon>
                        <ListItemText>
                          {submission.IsLocked ? 'Unlock' : 'Lock'} Submission
                        </ListItemText>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        onRestore(submission);
                        popupState.close();
                      }}
                      disabled={
                        submission.Status !== SubmissionStatus.Finished ||
                        !canSubmit ||
                        isActionRestricted
                      }
                    >
                      <ListItemIcon>
                        <AccountTree fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Restore as New Scenario</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        onSubmissionTransferTrigger(submission);
                        popupState.close();
                      }}
                      disabled={
                        submission.Status !== SubmissionStatus.Finished ||
                        !active
                      }
                    >
                      <ListItemIcon>
                        <Merge fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        Transfer to Different Scenario
                      </ListItemText>
                    </MenuItem>
                    <Divider />
                    <Box sx={{ my: 2 }}>
                      <Typography gutterBottom variant="body1" sx={{ mx: 2 }}>
                        Comparison Report
                      </Typography>
                      <MenuItem>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={comparing}
                              disabled={!canCompare}
                              onChange={() => onCompare(submission)}
                            />
                          }
                          label="Mark for comparison"
                        />
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          onClick={onLaunchComparison}
                          disabled={!canLaunchComparison}
                          variant="contained"
                        >
                          Launch Comparison Report
                        </Button>
                      </MenuItem>
                    </Box>
                  </StyledMenu>
                </>
              )}
            </PopupState>
          </Grid>
        </Grid>
      </Box>
    );
  }

  private handleClick = () => {
    this.props.onExpand(this.props.submission.id, !this.props.expanded);
  };

  private handleSetActive = () => {
    this.setState({ activationConfirming: true });
  };

  private cancelSetActive = () => {
    this.setState({ activationConfirming: false });
  };

  private confirmSetActive = async () => {
    const { instanceId, submission } = this.props;

    this.setState(
      { activationConfirming: false, activating: true, activationError: null },
      () => {
        this.triggerHeightChange();
      }
    );
    try {
      await API.create(
        `/instances/${instanceId}/scenarios/${submission.ScenarioID}/submissions/${submission.id}/activate`,
        {}
      );

      await (this.context as APICache).load(
        [
          `/clients`,
          `/instances/${instanceId}/scenarios/${submission.ScenarioID}`,
          `/instances/${instanceId}/scenarios/${submission.ScenarioID}/submissions`,
        ],
        true
      );
    } catch (error: any) {
      this.setState({ activating: false, activationError: error }, () => {
        this.triggerHeightChange();
      });
      return;
    }
    this.setState(INITIAL_STATE, () => {
      this.triggerHeightChange();
    });
  };

  private triggerHeightChange = () => {
    this.props.onExpand(this.props.submission.id, this.props.expanded);
  };
}

const SmallCard = styled(Card)(() => ({
  padding: '10px',
  '&.inactive': {
    opacity: 0.5,
  },
  boxShadow: 'none',
  border: `1px solid rgba(16, 22, 26, 0.15)`,
}));

export default SubmissionCard;
