import React, { useEffect, useContext } from 'react';

import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import { BugReport } from '@mui/icons-material';

import APICache from 'src/services/APICache';

import Flex from '../shared/Flex';
import Loader, { LoaderInfo } from '../shared/Loader';
import { Scenario } from '../../types/models';

import NonIdealState from '../shared/NonIdealState';

import CenteredSpinner from '../shared/CenteredSpinner';

import APICacheContext from '../shared/APICacheContext';

import ScenarioCard from './ScenarioCard';

interface Props {
  clientId: number;
  modelId?: number;
  instanceId?: number;
  scenarioId?: number;
  filter?: string;
  refreshScenarios?: number;

  onScenarioChange(id?: number): void;
  onFilterChange(filter?: string): void;
}

const renderPlaceholder = () => (
  <Flex flexDirection="column" flex="auto">
    <Flex alignItems="center" sx={{ paddingTop: 2 }}></Flex>
    <Typography variant="h5" color="disabled">
      Scenarios
    </Typography>
    <Flex
      flexDirection="column"
      py={2}
      flex="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="disabled">
        Select a model and model instance to see the scenarios associated with
        it.
      </Typography>
    </Flex>
  </Flex>
);

const ScenarioList: React.FC<Props> = ({
  clientId,
  modelId,
  instanceId,
  scenarioId,
  filter,
  onScenarioChange,
  onFilterChange,
  refreshScenarios,
}) => {
  const scenarioDetailsContext = useContext(APICacheContext);

  const needs = {
    scenarios: `/instances/${instanceId}/scenarios`,
  }

  const refresh = (): Promise<any[]> => {
    return (scenarioDetailsContext as APICache).load(
      [`/instances/${instanceId}/scenarios`],
      true
    );
  };

  const handleFilterChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    onFilterChange(newValue);
  };

  const handleScenarioClick = (scenario: Scenario) => {
    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    } else {
      onScenarioChange(scenario.id);
    }
  };

  const handleScenarioDeleted = (scenario: Scenario) => {
    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    }
  };

  const handleScenarioArchived = (scenario: Scenario) => {
    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    }
  };

  const handleScenarioUnarchived = (scenario: Scenario) => {
    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    }
  };

  const handleScenarioCopied = () => {
    // No op
  };

  const handleScenarioTransferred = () => {
    // No op
  };
  //   scenarios?: Scenario[];
  // }>(
  //   () => ({
  //     scenarios: `/instances/${instanceId}/scenarios`,
  //   }),
  //   [instanceId, refreshScenarios]
  // );

  useEffect(() => {
    if (refreshScenarios !== undefined && refreshScenarios >= 0) {
      refresh();
    }
  }, [refreshScenarios]);

  const renderSection = (year: number, scenarios: Scenario[]) => {
    if (!clientId || !modelId) {
      return null;
    }

    return (
      <>
        <Box mb={1}>
          <Typography variant="caption" color="muted">
            {year}
          </Typography>
        </Box>
        {scenarios.map((scenario, i) => (
          <Box mt={i === 0 ? 0 : 2} key={scenario.id}>
            <ScenarioCard
              clientId={clientId}
              modelId={modelId}
              selectedScenarioId={scenarioId}
              scenario={scenario}
              onClick={handleScenarioClick}
              onDeleted={handleScenarioDeleted}
              onUnarchived={handleScenarioUnarchived}
              onTransferred={handleScenarioTransferred}
              onCopied={handleScenarioCopied}
              onArchived={handleScenarioArchived}
            />
          </Box>
        ))}
      </>
    );
  };

  const renderBody = ({
    loading,
    data,
  }: LoaderInfo<{ scenarios: Scenario[] }>) => {
    const scenarios = (data.scenarios || [])
      .filter((s) => !!s.Archive_Flag === (filter === 'archive'))
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    if (scenarios === undefined || scenarios.length === 0) {
      if (loading) {
        return (
          <Box p={3}>
            <CenteredSpinner />
          </Box>
        );
      } else {
        return (
          <Box p={3}>
            <NonIdealState icon={<BugReport />} title="No scenarios found" />
          </Box>
        );
      }
    }

    const years = scenarios
      .map((s) => s.Year)
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort((a, b) => b - a);

    return (
      <>
        {years.map((year) => (
          <Box mt={2} key={year}>
            {renderSection(
              year,
              scenarios.filter((s) => s.Year === year)
            )}
          </Box>
        ))}
      </>
    );
  };

  if (instanceId === undefined) {
    return renderPlaceholder();
  }

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" sx={{ paddingTop: 2, width: '95%' }}>
      <Box flex="auto">
        <Typography variant="h5">Scenarios</Typography>
      </Box>

      <ToggleButtonGroup
        size="small"
        color="primary"
        value={filter || 'current'}
        exclusive
        onChange={handleFilterChange}
        aria-label="Platform"
      >
        <ToggleButton value="current">Current</ToggleButton>
        <ToggleButton value="archive">Archive</ToggleButton>
      </ToggleButtonGroup>
      </Flex>
      <Loader
      needs={needs}
      render={renderBody}
      handleErrors={true}
      force={refreshScenarios === undefined}
      handleLoading={false}
      />
    </Flex>
  );
};

export default ScenarioList;
