import { FunctionComponent, useContext, useState } from 'react';

import { Scenario, Submission } from 'src/types/models';

import { enqueueSnackbar } from 'notistack';
import APICache from 'src/services/APICache';

import PopupDialog from '../shared/PopupDialog';

import * as API from '../../services/API';

import APICacheContext from '../shared/APICacheContext';

interface ILockGroupDialogProps {
  modelId: number;
  instanceId: number;
  scenario: Scenario;
  submission: Submission | undefined;
  isOpen: boolean;
  onCancel: () => void;
  onComplete: () => void;
}

interface ILockGroups {
 busy: boolean;
}

const initialState: ILockGroups = {
  busy: false,
};

const LockSubmissionDialog: FunctionComponent<ILockGroupDialogProps> = ({
  modelId,
  instanceId,
  scenario,
  submission,
  isOpen,
  onCancel,
  onComplete,
}) => {
  const lockSubmissionDialogContext = useContext(APICacheContext);

  const [state, setState] = useState<ILockGroups>(initialState);

  const onLock = async () => {
    setState({ busy: true });
    try {
      await API.create(
        `/instances/${instanceId}/scenarios/${scenario?.id}/submissions/${submission?.id}/lock`,
        {
          isLocked: !submission?.IsLocked,
        }
      );
      await (lockSubmissionDialogContext as APICache).load(
        [
          `/clients`,
          `/instances/${instanceId}/scenarios/${submission?.ScenarioID}`,
          `/instances/${instanceId}/scenarios/${submission?.ScenarioID}/submissions`,
        ],
        true
      );
      setState({ busy: false });
      onComplete();
    } catch (error: any) {
      enqueueSnackbar(error.body.message, { variant: 'error' });
      onComplete();
      setState({ busy: false });
      return;
    }
  };

  return (
    <PopupDialog
      isLoading={state.busy}
      title={submission?.IsLocked ? 'Unlock Submission' : 'Lock Submission'}
      open={isOpen}
      close={onCancel}
      primaryButtons={[
        {
          id: 'removeButton',
          label: submission?.IsLocked ? 'Unlock' : 'Lock',
          disabled: state.busy,
          onClick: () => {
            onLock();
          },
        },
      ]}
    >
      Are you sure that you would like to{' '}
      {submission?.IsLocked ? 'Unlock' : 'Lock'} the submission?
    </PopupDialog>
  );
};

export default LockSubmissionDialog;
