import React, { FunctionComponent, useContext, useEffect } from 'react';
import { every, head } from 'lodash';
import { Box, Button, Container, Grid, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSearchParams } from 'react-router-dom';

import { isModelOwner } from 'src/utils/profile';

import { parseId } from '../../utils/parsing';

import { ProfileProps, withProfile } from '../shared/AuthController';
import ClientIndex from '../shared/ClientIndex';

import SelectModel from '../shared/SelectModel';
import SelectModelInstance from '../shared/SelectModelInstance';

import APICacheContext from '../shared/APICacheContext';

import {
  DefaultModelFiles,
  ModelInstance,
  RowIdentifier,
  Scenario,
  XRefDate,
} from '../../types/models';

import { UploadContext } from '../shared/UploadFilesContext';

import Flex from '../shared/Flex';

import useSetState from '../../hooks/useSetState';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';

import { useData } from '../../hooks/useData';

import ScenarioDetails from './ScenarioDetails';
import ScenarioDetailsPlaceholder from './ScenarioDetails/Placeholder';
import ScenarioList from './ScenarioList';
import ManageDefaultModelFiles from './ManageDefaultModelFiles';

type Props = ProfileProps;

const DEFAULT_FILTER = 'current';

interface IScenarioManagerState {
  isManageDefaultModelFilesDialogOpen: boolean;
  doesInstanceHaveGroups: boolean;
  defaultModelFiles: DefaultModelFiles[];
  refreshScenarios: number;
}

const ScenarioManager: FunctionComponent<Props> = (props: Props) => {
  const [state, setState] = useSetState<IScenarioManagerState>({
    isManageDefaultModelFilesDialogOpen: false,
    doesInstanceHaveGroups: false,
    defaultModelFiles: [],
    refreshScenarios: 0,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { width } = useWindowDimensions();

  const params = () => {
    const { profile } = props;

    const clientId =
      profile && profile.User.ClientID !== null
        ? profile.User.ClientID
        : parseId(searchParams.get('client'));
    const modelId = parseId(searchParams.get('model'));
    const instanceId = parseId(searchParams.get('instance'));
    const scenarioId = parseId(searchParams.get('scenario'));
    const filter = searchParams.get('filter') || DEFAULT_FILTER;

    return { clientId, modelId, instanceId, scenarioId, filter, profile };
  };

  const contextValue = useContext(APICacheContext);
  const uploadContext = useContext(UploadContext);

  const setRefreshScenarios = () => {
    setState({
      refreshScenarios: state.refreshScenarios + 1
    });
  } 

  useEffect(() => {
    if (uploadContext?.fileUploadData.isFilesProcessingDone) {
      if (instanceId) {
        // Using an IIFE
        (async () => {
          try {
            const result = await contextValue!.load(
              [
                `/instances/${instanceId}/row_identifiers?type=Group`,
                `/default_model_files/${instanceId}`,
              ],
              true
            );
            if (every(head(result), { GroupID: 0 })) {
              setState({
                doesInstanceHaveGroups: false,
              });
            } else {
              setState({
                doesInstanceHaveGroups: true,
              });
            }
            setState({
              defaultModelFiles: result[1],
            });
          } catch (error) {
            // no op
          }
        })();
      }
    }
  }, [uploadContext?.fileUploadData.isFilesProcessingDone]);

  const handleClientChange = (id?: number) => {
    if (id === params().clientId) {
      return;
    }

    if (id === undefined) {
      searchParams.delete('client');
    } else {
      searchParams.set('client', id.toString());
    }
    searchParams.delete('model');
    searchParams.delete('instance');
    searchParams.delete('scenario');
    setSearchParams(searchParams);
  };

  const handleChangeModel = (id: number) => {
    if (id === params().modelId) {
      return;
    }

    searchParams.set('model', id.toString());
    searchParams.delete('instance');
    searchParams.delete('scenario');
    setSearchParams(searchParams);
  };

  const handleChangeModelInstance = (id: number) => {
    if (id === params().instanceId) {
      return;
    }

    searchParams.set('instance', id.toString());
    searchParams.delete('scenario');
    setSearchParams(searchParams);
  };

  const handleScenarioChange = async (id?: number) => {
    if (id === params().scenarioId) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (id === undefined) {
      searchParams.delete('scenario');
    } else {
      searchParams.set('scenario', id.toString());
    }
    setSearchParams(searchParams);

    await contextValue?.load(
      [
        `/default_model_files/${instanceId}`,
        `/instances/${instanceId}/row_identifiers?type=Group`,
      ],
      true
    );
  };

  const handleFilterChange = (filter?: string) => {
    if (filter === params().filter) {
      return;
    }

    if (filter === DEFAULT_FILTER || filter === undefined) {
      searchParams.delete('filter');
    } else {
      searchParams.set('filter', filter.toString());
    }
    searchParams.delete('scenario');

    setSearchParams(searchParams);
  };

  const handleChangeModelFilesDialog = () => {
    setState({
      isManageDefaultModelFilesDialogOpen: true,
    });
  };

  const { clientId, modelId, instanceId, scenarioId, filter, profile } = params();

  const isOwner = profile !== undefined && modelId !== undefined && isModelOwner(profile, modelId);

  useEffect(() => {
    if (instanceId) {
      // Using an IIFE
      (async () => {
        try {
          const result = await contextValue!.load(
            [
              `/instances/${instanceId}/row_identifiers?type=Group`,
              `/default_model_files/${instanceId}`,
            ],
            true
          );
          if (every(head(result), { GroupID: 0 })) {
            setState({
              doesInstanceHaveGroups: false,
            });
          } else {
            setState({
              doesInstanceHaveGroups: true,
            });
          }
          setState({
            defaultModelFiles: result[1],
          });
        } catch (error) {
          // no op
        }
      })();
    }
  }, [instanceId]);

  const { data } = useData<{
    modelInstance?: ModelInstance;
    scenarios?: Scenario[];
    rowIdentifiers?: RowIdentifier[];
    dates?: XRefDate[];
  }>(
    () => ({
      modelInstance:
        instanceId !== undefined && modelId !== undefined
          ? `/instances/${instanceId}`
          : undefined,
    }),
    [instanceId]
  );

  const refresh = async (): Promise<void> => {
    const result = await contextValue?.load(
      [`/default_model_files/${instanceId}`],
      true
    );
    setState({
      defaultModelFiles: result && result[0],
    });
  };

  if (clientId === undefined) {
    return <ClientIndex onSelect={handleClientChange} />;
  }

  return (
    <Container maxWidth={width > 1300 ? 'xl' : false}>
      <ManageDefaultModelFiles
        isManageDefaultModelFilesDialogOpen={
          state.isManageDefaultModelFilesDialogOpen
        }
        handleCloseManageDefaultModelFilesDialog={() =>
          setState({
            isManageDefaultModelFilesDialogOpen: false,
          })
        }
        modelInstanceId={instanceId}
        doesInstanceHaveGroups={state.doesInstanceHaveGroups}
        defaultModelFiles={state.defaultModelFiles}
        refresh={refresh}
      />
      <Grid container alignItems="center">
        <Grid item md={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={3.5}>
              <SelectModel
                clientId={clientId}
                modelId={modelId}
                onChange={handleChangeModel}
                size="small"
              />
            </Grid>
            <Grid item md={3.5}>
              <SelectModelInstance
                clientId={clientId}
                modelId={modelId}
                modelInstanceId={instanceId}
                onChange={handleChangeModelInstance}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} container justifyContent="flex-end">
          {instanceId && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleChangeModelFilesDialog}
              disabled={!data.modelInstance?.Published || !isOwner}
              {...(!data.modelInstance?.Published && {
                title:
                  'The default model file functionality is only available for published model instances.',
              })}
            >
              Manage default model files
            </Button>
          )}
        </Grid>
      </Grid>
      <Box pt={2}>
        <Border />
      </Box>
      <Flex>
        <Flex
          flexDirection="column"
          width="40%"
          maxWidth="40%"
          sx={{ marginBottom: 5 }}
        >
          <ScenarioList
            clientId={clientId}
            modelId={modelId}
            instanceId={instanceId}
            scenarioId={scenarioId}
            filter={filter}
            onScenarioChange={handleScenarioChange}
            onFilterChange={handleFilterChange}
            refreshScenarios={state.refreshScenarios}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="60%"
          px={5}
          sx={
            scenarioId
              ? {
                  background: 'rgb(245, 248, 250)',
                  borderRight: `1px solid #E1E8ED`,
                  borderLeft: `1px solid #E1E8ED`,
                }
              : {}
          }
        >
          {modelId === undefined ||
          instanceId === undefined ||
          scenarioId === undefined ? (
            <ScenarioDetailsPlaceholder />
          ) : (
            <ScenarioDetails
              modelId={modelId}
              instanceId={instanceId}
              scenarioId={scenarioId}
              key={scenarioId}
              refreshScenarios={setRefreshScenarios}
            />
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export const Border = styled('hr')({
  backgroundColor: `${grey[300]}`,
  border: `none`,
  height: `1
px`,
  width: `100%`,
  margin: 0,
});

export default withProfile(ScenarioManager);
