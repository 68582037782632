import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Link, unstable_usePrompt, useLocation } from 'react-router-dom';

import {
  every,
  filter,
  find,
  flow,
  forEach,
  forIn,
  get,
  head,
  includes,
  isArray,
  isEmpty,
  keys,
  last,
  map,
  mapValues,
  omit,
  orderBy,
  pickBy,
  reduce,
  reject,
  some,
  startsWith,
  uniq,
  uniqBy,
  values,
} from 'lodash';

import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  BarChart,
  Calculate,
  CheckCircle,
  Clear,
  CloudUpload,
  Error,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
} from '@mui/icons-material';

import { Allotment, LayoutPriority } from 'allotment';

import { useSnackbar } from 'notistack';

import { alpha } from '@mui/material/styles';

import { errorMessage, warnMessage } from 'src/utils/StaticErrorMessages';

import { GetParams } from '../../../utils/history';
import { useData } from '../../../hooks/useData';
import {
  GeneratedFile,
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  Submission,
  SubmissionStatus,
} from '../../../types/models';

import useSetState from '../../../hooks/useSetState';

import Flex from '../../shared/Flex';

import NonIdealState from '../../shared/NonIdealState';

import {
  WEB_ASSUMPTIONS_LOAD,
  WEB_ASSUMPTIONS_POST,
  WEB_ASSUMPTIONS_PUBLISH,
  WEB_ASSUMPTIONS_SANDBOX_PREVIEW,
} from '../../../url';

import * as API from '../../../services/API';

import { getDataAtIntervals } from '../../../utils/getDataAtIntervals';

import {
  formatParameters,
  IFormatParameters,
} from '../../Visualization/formatParameters';

import useDynamicCallback from '../../../hooks/useDynamicCallback';

import {
  FloatingPanelContainer,
  FloatingPanelHeaderContainer,
  FloatingPanelHeaderLayoutContainer,
  FloatingPanelHeaderLayoutTextContainer,
  FloatingPanelHeaderLayoutTextIconsContainer,
} from '../../shared/MultipleFilesUploadingPanel';

import { containsOnly } from '../../../utils/misc';

import {
  IBooleanType,
  portfolioNumScenarios,
} from '../../Visualization/visualizationConstants';

import Breadcrumbs, { IBreadcrumb } from './NavBreadCrumbs';
import SelectInputsModal from './SelectInputsModal';

import InputTabs from './InputTabs';
import { ISandboxRefresh, IWebAssumptionsLoad } from './types';
import OutputsPanel, { IChartData, IOutputsPanelState } from './OutputsPanel';
import PublishChangesDialog from './PublishChangesDialog';
import AssumptionsUploadIcon from './AssumptionsUploadIcon';
import { EditAssumptionsDetailContext } from './EditAssumptionsDetailContext';


export const poll = async function (fn: any, fnCondition: any, ms: number) {
  let result = await fn();
  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }
  return result;
};

export const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export type ISelectedInputs = Record<
  RowIdentifier['Name'],
  RowIdentifier & { addedAt: string }
>;
interface IInputsDimensionHeadersResponse {
  Name: RowIdentifier['Name'];
  Field: string;
}

interface IInputsSeriesResponse {
  Id: number;
  Input_RowID: string;
  Values: string[];
  Group: string | null;
}

export interface IInputsResponse {
  Name: RowIdentifier['Name'];
  ValueHeaders: string[];
  DimensionHeaders: IInputsDimensionHeadersResponse[];
  Series: IInputsSeriesResponse[];
  id: RowIdentifier['id'];
  FormatKey: IFormatParameters;
  TabName: string;
  InputCategory: string;
}

export interface IModifiedRowData {
  Input: string;
  Data: any[];
  isDirty: boolean;
  RowIdentifierId: Number | null;
}

export interface IOutputsPreviewData {
  ChartName: string;
  Data: IChartData;
}

interface State {
  isSelectInputNamesDialogOpen: boolean;
  selectedInputs: ISelectedInputs;
  selectedInputTab: string;
  showOutputChartPanel: boolean;
  loading: boolean;
  inputsResponse: IInputsResponse[];
  originalInputsResponse: IInputsResponse[];
  isPublishChangesDialogOpen: boolean;
  isPublishChangesDialogLoading: boolean;
  groupedSubmissions: Record<
    Submission['id'],
    Submission & { GroupName: string }
  >;
  isSubmissionsRefreshPanelExpanded: boolean;
  isSandboxRefreshPanelExpanded: boolean;
  displaySubmissionsRefreshPanel: boolean;
  displaySandboxRefreshPanel: boolean;
  modifiedChartData?: IOutputsPanelState;
  isPublishEnabled: boolean;
  modifiedRowData: IModifiedRowData[];
  showOutputsPreviewButton: boolean;
  outputsPreviewData?: IOutputsPreviewData[];
  groupedSandboxRefreshes: (ISandboxRefresh & { GroupName: string })[];
  isSandboxChangesDialogLoading: boolean;
  showOutputsChartButton: boolean;
  invalidInputs: any[];
  lockedGroupNames: string[];
}

const EditAssumptionsDetail: FunctionComponent = () => {
  const [state, setState] = useSetState<State>({
    isSelectInputNamesDialogOpen: true,
    selectedInputs: {},
    selectedInputTab: '',
    showOutputChartPanel: false,
    loading: false,
    inputsResponse: [],
    originalInputsResponse: [],
    isPublishChangesDialogOpen: false,
    isPublishChangesDialogLoading: false,
    groupedSubmissions: {},
    isSubmissionsRefreshPanelExpanded: true,
    isSandboxRefreshPanelExpanded: true,
    displaySubmissionsRefreshPanel: false,
    displaySandboxRefreshPanel: false,
    modifiedChartData: undefined,
    isPublishEnabled: false,
    modifiedRowData: [],
    showOutputsPreviewButton: false,
    outputsPreviewData: undefined,
    groupedSandboxRefreshes: [],
    isSandboxChangesDialogLoading: false,
    showOutputsChartButton: false,
    invalidInputs: [],
    lockedGroupNames: [],
  });

  const inputNameKeys = filter(
    keys(window.sessionStorage),
    (key) =>
      startsWith(key, 'gridState-inputName-') ||
      startsWith(key, 'filterState-inputName-') ||
      startsWith(key, 'columnState-inputName-')
  );

  useEffect(() => {
    forEach(inputNameKeys, (key) => window.sessionStorage.removeItem(key));
    window.sessionStorage.removeItem('chartData');
    return () => {
      forEach(inputNameKeys, (key) => window.sessionStorage.removeItem(key));
    };
  }, []);

  /**
   * For Publish changes
   */
  useEffect(() => {
    (async () => {
      if (
        containsOnly(
          [
            SubmissionStatus.Failed,
            SubmissionStatus.Finished,
            SubmissionStatus.NoDataReturned,
          ],
          map(state.groupedSubmissions, 'Status')
        )
      ) {
        const chartStorageData = JSON.parse(
          localStorage.getItem('chartData') || '{}'
        );
        const refreshedCharts = filter(
          chartStorageData.chartTabs,
          (tab) => chartStorageData.refreshChart[tab.eventKey]
        );
        if (!isEmpty(refreshedCharts)) {
          forEach(refreshedCharts, async (tab) => {
            if (get(chartStorageData, ['refreshChart', tab.eventKey])) {
              const chartData = await API.create<GeneratedFile>(
                '/chart_refresh/custom',
                {
                  ModelInstanceID: modelInstanceId,
                  ScenarioIDs: chartStorageData.scenarios[tab.eventKey],
                  RowIdentifierIDs: [
                    ...map(
                      chartStorageData.dimensionInstances[tab.eventKey],
                      'id'
                    ),
                    chartStorageData.outputId[tab.eventKey],
                  ],
                  Timescales: [chartStorageData.timescale[tab.eventKey]],
                  Aggregate_Flag: chartStorageData.aggregateFlag[tab.eventKey]
                    ? 1
                    : 0,
                }
              );
              if (chartData.Status === SubmissionStatus.Queued) {
                const chartJsonData = await getDataAtIntervals(
                  `/chart_refresh/${chartData.id}`,
                  'Status'
                );
                if (chartJsonData.Status === SubmissionStatus.Failed) {
                  enqueueSnackbar(chartJsonData.Status_Message, {
                    variant: 'error',
                  });
                  chartStorageData.refreshChart[tab.eventKey] = false;
                  chartStorageData.chartLoading[tab.eventKey] = false;
                }
                if (chartJsonData.Status === SubmissionStatus.NoDataReturned) {
                  enqueueSnackbar(chartJsonData.Status_Message, {
                    variant: 'warning',
                  });
                  chartStorageData.refreshChart[tab.eventKey] = false;
                  chartStorageData.chartLoading[tab.eventKey] = false;
                } else if (chartJsonData.Status === SubmissionStatus.Finished) {
                  const chartJson: IChartData = {
                    ...chartJsonData.data,
                    Dates: chartJsonData.data.Dates,
                    PublishedData: chartJsonData.data.PublishedData.slice(
                      0,
                      portfolioNumScenarios
                    ),
                    PreviewData: [],
                  };
                  chartStorageData.chartData[tab.eventKey] = chartJson;
                  chartStorageData.refreshedChartData[tab.eventKey] = {
                    Dates: chartJson.Dates,
                    PublishedData: structuredClone(chartJson.PublishedData),
                    PreviewData: structuredClone(chartJson.PreviewData),
                  };
                  setState({
                    modifiedChartData: chartStorageData,
                  });
                }
              }
            }
          });
          handleSaveSelectInputModalDialog(state.selectedInputs, true);
        } else {
          handleSaveSelectInputModalDialog(state.selectedInputs, true);
        }
      }
    })();
  }, [state.groupedSubmissions, state.selectedInputs]);

  /**
   * For Sandbox changes
   */
  useEffect(() => {
    (async () => {
      if (
        containsOnly(
          [
            SubmissionStatus.Failed,
            SubmissionStatus.Finished,
            SubmissionStatus.NoDataReturned,
          ],
          map(state.groupedSandboxRefreshes, 'Status')
        )
      ) {
        const calculationsData = state.groupedSandboxRefreshes;
        const getUniqueCalculationData = uniqBy(
          calculationsData,
          'ForecastDataRefreshID'
        );
        try {
          const chartJsonData = await getDataAtIntervals(
            `/chart_refresh/${
              (
                head(getUniqueCalculationData) as ISandboxRefresh & {
                  GroupName: string;
                }
              ).ForecastDataRefreshID
            }`,
            'Status'
          );
          if (chartJsonData.Status === SubmissionStatus.Failed) {
            let invalidInputs = chartJsonData.Status_Message
              ? JSON.parse(chartJsonData.Status_Message)
              : [];
            if (invalidInputs.length > 0) {
              return;
            } else {
              enqueueSnackbar(chartJsonData.Status_Message, {
                variant: 'error',
              });
            }
          }
          if (chartJsonData.Status === SubmissionStatus.NoDataReturned) {
            enqueueSnackbar(chartJsonData?.Status_Message, {
              variant: 'warning',
            });
          } else if (chartJsonData.Status === SubmissionStatus.Finished) {
            const chartData = reduce(
              chartJsonData.data,
              (
                a: Record<string, IChartData>,
                c: IChartData & { ChartName: string }
              ) => {
                a[c.ChartName] = omit(c, 'ChartName');
                return a;
              },
              {}
            );
            enqueueSnackbar(`Sandbox Chart(s) generated successfully.`, {
              variant: 'success',
            });
            const modifiedChartValues = mapValues(chartData, (item, key) => {
              return {
                Dates: chartData[key].Dates,
                PublishedData: map(
                  chartData[key].PublishedData.slice(0, portfolioNumScenarios),
                  (_) => ({ ..._, visible: true })
                ),
                PreviewData: map(
                  chartData[key].PreviewData.slice(0, portfolioNumScenarios),
                  (_) => ({ ..._, visible: true })
                ),
              };
            });
            setState({
              groupedSandboxRefreshes: [],
              isSandboxChangesDialogLoading: false,
              showOutputChartPanel: true,
              showOutputsPreviewButton: false,

              modifiedChartData: {
                ...(state.modifiedChartData as IOutputsPanelState),
                chartData: modifiedChartValues,
                refreshedChartData: modifiedChartValues,
                selectedChartRange: reduce(
                  state.modifiedChartData?.chartTabs,
                  (a, c) => {
                    a[c.eventKey] = null;
                    return a;
                  },
                  {} as Record<string, [string, string] | null>
                ),
                selectedChartRangeIndex: reduce(
                  state.modifiedChartData?.chartTabs,
                  (a, c) => {
                    a[c.eventKey] = undefined;
                    return a;
                  },
                  {} as Record<string, [number, number] | undefined>
                ),
                isGenerateChartPanelOpen: {
                  ...state.modifiedChartData?.isGenerateChartPanelOpen,
                  [state.modifiedChartData?.selectedChartTab as string]: true,
                },
              },
            });
          }
        } catch (e: any) {
          enqueueSnackbar(e, {
            variant: 'error',
          });
          setState({
            groupedSandboxRefreshes: [],
            isSandboxChangesDialogLoading: false,
          });
        }
      }
    })();
  }, [state.groupedSandboxRefreshes]);

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const params = GetParams(location.search);

  const { clientId, modelId, modelInstanceId, scenarioId } = params;

  const { data } = useData<{
    rowIdentifiers?: RowIdentifier[];
    model?: Model;
    modelInstance?: ModelInstance;
    scenario?: Scenario;
    submission?: Submission[];
  }>(
    () => ({
      rowIdentifiers:
        modelInstanceId !== undefined
          ? `/instances/${modelInstanceId}/row_identifiers`
          : undefined,
      model:
        clientId !== undefined
          ? `/clients/${clientId}/models/${modelId}`
          : undefined,
      modelInstance:
        clientId !== undefined && modelId !== undefined
          ? `/instances/${modelInstanceId}`
          : undefined,
      scenario:
        clientId !== undefined &&
        modelId !== undefined &&
        modelInstanceId !== undefined
          ? `/instances/${modelInstanceId}/scenarios/${scenarioId}`
          : undefined,
      submission: `/instances/${modelInstanceId}/scenarios/${scenarioId}/submissions`,
    }),
    []
  );

  const outputs = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Output' }),
    [modelInstanceId, data]
  );

  const groups = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Group' }),
    [modelInstanceId, data]
  );

  useEffect(() => {
    if (groups && groups.length > 0) {
      let groupNameMappings: { [key: number]: string } = groups.reduce(
        (mapping, group) => ({ ...mapping, [group.id]: group.Name }),
        {}
      );
      let lockedGroupIds = filter(data.submission, { IsLocked: true });
      const lockedGroupNames = uniq(
        lockedGroupIds.map((submission) => {
          if (submission && submission.GroupRowIdentifierID) {
            return groupNameMappings[submission.GroupRowIdentifierID];
          } else {
            return undefined;
          }
        })
      );
      setState({
        lockedGroupNames: lockedGroupNames.filter((name): name is string => name !== undefined),
      });
    }
  }, [groups]);

  // const backToScenarioManager = () => {
  //   if (some(state.modifiedRowData, { isDirty: true })) {
  //     confirm({
  //       description:
  //         'Are you sure you want to exit the Assumption Editor and discard all of your current changes without saving?',
  //     }).then(() => {
  //       navigate(
  //         `/scenario-manager?${getSearchParams({
  //           client: clientId,
  //           model: modelId,
  //           instance: modelInstanceId,
  //           scenario: scenarioId,
  //         })}`,
  //         {
  //           replace: true,
  //         }
  //       );
  //     });
  //   } else {
  //     navigate(-1);
  //   }
  // };

  const getSearchParams = (obj: Record<string, any>): string => {
    const params = new URLSearchParams(obj);
    return params.toString();
  };

  const breadcrumbs: IBreadcrumb[] = useMemo(
    () => [
      {
        text: 'Back to Scenario Manager',
        url: `/scenario-manager?${getSearchParams({
          client: clientId,
          model: modelId,
          instance: modelInstanceId,
          scenario: scenarioId,
        })}`,
      },
      {
        text: `${data?.model?.Name} - ${data?.modelInstance?.Name} - ${data?.scenario?.Name}`,
      },
    ],
    [data]
  );

  const handleOpenSelectInputModalDialog = useCallback((): void => {
    setState({
      isSelectInputNamesDialogOpen: true,
    });
  }, [state.isSelectInputNamesDialogOpen]);

  const handleCloseSelectInputModalDialog = useCallback((): void => {
    setState({
      isSelectInputNamesDialogOpen: false,
    });
  }, [state.isSelectInputNamesDialogOpen]);

  const setInvalidInputs = (invalidInputs: any[]) => {
    setState({ invalidInputs: invalidInputs });
  }

  const handleSaveSelectInputModalDialog = async (
    inputs: ISelectedInputs,
    disableNotification?: boolean
  ): Promise<void> => {
    const difference = pickBy(inputs, (v, k) => !state.selectedInputs[k]);
    const selectedInputsValues = orderBy(inputs, ['addedAt'], ['desc']);
    setState({
      isSelectInputNamesDialogOpen: false,
      selectedInputTab: selectedInputsValues[0].Name,
      selectedInputs: inputs,
      loading: !isEmpty(difference),
      modifiedRowData: state.modifiedRowData.filter((i) =>
        selectedInputsValues.find((j) => j.Name === i.Input)
      ),
    });
    const webAssumptionsLoadPostData = await API.create<IWebAssumptionsLoad>(
      WEB_ASSUMPTIONS_POST(
        data?.modelInstance?.id as number,
        data?.scenario?.id as number
      ),
      {
        Row_Identifier_Ids: map(
          values(disableNotification ? inputs : difference),
          'id'
        ),
      }
    );

    const webAssumptionsLoad = await getDataAtIntervals(
      WEB_ASSUMPTIONS_LOAD(
        data?.modelInstance?.id as number,
        data?.scenario?.id as number,
        webAssumptionsLoadPostData?.id as number
      ),
      'Status'
    );

    if (webAssumptionsLoad?.Status === SubmissionStatus.Failed) {
      enqueueSnackbar(webAssumptionsLoad?.Status_Message, {
        variant: 'error',
      });
      setState({
        loading: false,
      });
    }

    if (webAssumptionsLoad?.Status === SubmissionStatus.Finished) {
      !disableNotification &&
        enqueueSnackbar('Inputs loaded successfully.', {
          variant: 'success',
        });
      const webAssumptionsLoadResponse: IInputsResponse[] = map(
        webAssumptionsLoad?.data.Inputs,
        (i) => {
          return {
            ...i,
            id: inputs[i.Name].id,
            FormatKey: find(formatParameters, {
              formatKey: inputs[i.Name].FormatKey,
            }) as IFormatParameters,
            TabName: inputs[i.Name].TabName,
            InputCategory: inputs[i.Name].InputCategory,
          };
        }
      );
      if (disableNotification) {
        forEach(inputNameKeys, (key) => window.sessionStorage.removeItem(key));
        setState({
          modifiedRowData: [],
        });
      }
      setState({
        showOutputsChartButton: true,
        inputsResponse: disableNotification
          ? webAssumptionsLoadResponse
          : [...state.inputsResponse, ...webAssumptionsLoadResponse],
        originalInputsResponse: disableNotification
          ? webAssumptionsLoadResponse
          : [...state.originalInputsResponse, ...webAssumptionsLoadResponse],
        isPublishChangesDialogLoading: false,
        isPublishEnabled: some(
          state.modifiedRowData.filter((i) =>
            selectedInputsValues.find((j) => j.Name === i.Input)
          ),
          { isDirty: true }
        ),
        groupedSubmissions: {},
        loading: false,
      });
    }
  };

  const handleChangeTab = useCallback(
    (tab: string): void => {
      setState({
        selectedInputTab: tab,
      });
    },
    [state.selectedInputTab]
  );

  const closeTab = useCallback(
    (tab: string): void => {
      const remainingTabs: ISelectedInputs = omit(state.selectedInputs, tab);
      const tabKey = tab;
      const selectedInputTab = !isEmpty(
        find(values(remainingTabs), { InputCategory: tabKey })
      )
        ? (find(values(remainingTabs), {
            InputCategory: tabKey,
          }) as RowIdentifier)
        : (values(remainingTabs)[0] as RowIdentifier);

      window.sessionStorage.removeItem(`gridState-inputName-${tab}-modified`);
      setState({
        selectedInputs: remainingTabs,
        selectedInputTab: selectedInputTab ? selectedInputTab.Name : '',
        modifiedRowData: reject(state.modifiedRowData, { Input: tab }),
      });
    },
    [state.selectedInputs, state.modifiedRowData]
  );

  const handleOutputsVisibility = useDynamicCallback(() => {
    setState({
      showOutputChartPanel: !state.showOutputChartPanel,
      showOutputsPreviewButton:
        some(state.modifiedRowData, { isDirty: true }) &&
        some(values(state.modifiedChartData?.refreshChart), (i) => i),
    });
    localStorage.removeItem('chartData');
  });

  const handleOutputsPreview = useCallback(async () => {
    const chartsRefreshedState = state.modifiedChartData
      ?.refreshChart as IBooleanType;

    const refreshedCharts = filter(
      keys(chartsRefreshedState),
      (key) => chartsRefreshedState[key]
    );
    setState({
      isSandboxChangesDialogLoading: true,
    });
    map(state.modifiedRowData, (i, index) => {
      const data = find(state.selectedInputs as ISelectedInputs, {
        Name: i.Input,
      });
      state.modifiedRowData[index].RowIdentifierId = data?.id as Number;
    });
    try {
      const sandboxData: ISandboxRefresh[] = await API.create(
        WEB_ASSUMPTIONS_SANDBOX_PREVIEW(
          data?.modelInstance?.id as number,
          data?.scenario?.id as number
        ),
        {
          Assumptions: state.modifiedRowData,
          Outputs: map(refreshedCharts, (chart) => {
            return {
              ChartName: chart,
              RowIdentifierIds: map(
                state.modifiedChartData?.dimensionInstances[chart],
                'id'
              ),
              OutputName: (
                find(outputs, {
                  id: state.modifiedChartData?.outputId[chart],
                }) as RowIdentifier
              ).Name,
              TimeScale: state.modifiedChartData?.timescale[chart],
              AggregateFlag: state.modifiedChartData?.aggregateFlag[chart],
            };
          }),
        }
      );

      const groupsData = await API.load(
        `/instances/${data?.modelInstance?.id}/row_identifiers?type=Group`
      );

      setState({
        groupedSandboxRefreshes: map(sandboxData, (i) => {
          return {
            ...i,
            GroupName: find(groupsData, { id: i.RowIdentifierID })?.Name,
          };
        }),
        displaySandboxRefreshPanel: true,
      });

      const forecastRefreshId = sandboxData[0].ForecastDataRefreshID;

      const pollApi = async () => {
        return await API.load(
          `/calculation_invocations/forecast_data_refreshes/${forecastRefreshId}/calculation_invocations`
        );
      };

      const validateData = (data: ISandboxRefresh[]) => {
        // Check if all status properties are 2 or -1
        const valid = data.every(
          (obj) =>
            obj.Status === SubmissionStatus.Finished ||
            obj.Status === SubmissionStatus.Failed ||
            obj.Status === SubmissionStatus.NoDataReturned
        );

        if (valid) {
          return data;
        } else {
          return null;
        }
      };

      const poll = async (
        fetchFunction: Function,
        validateFunction: Function,
        interval: number
      ): Promise<ISandboxRefresh[] | null> => {
        return new Promise(async (resolve) => {
          const intervalId = setInterval(async () => {
            const result = await fetchFunction();
            setState({
              groupedSandboxRefreshes: map(result, (i) => {
                return {
                  ...i,
                  GroupName: find(groupsData, { id: i.RowIdentifierID })?.Name,
                };
              }),
            });
            const validatedResult = validateFunction(result);
            if (validatedResult !== null) {
              clearInterval(intervalId);
              resolve(validatedResult);
            }
          }, interval);
        });
      };

      const startPolling = async () => {
        const data = await poll(pollApi, validateData, 2500);
        if (data !== null && data.length > 0) {
          let InvalidInputs: any[] = [];
          data.forEach((i: ISandboxRefresh): void => {
            if (i.Status === SubmissionStatus.Failed) {
              let invalidInputs = i.Status_Message
                ? JSON.parse(i.Status_Message)
                : [];
              if (invalidInputs.length > 0) {
                InvalidInputs = InvalidInputs.concat(invalidInputs);
                enqueueSnackbar(
                  errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE,
                  {
                    variant: 'error',
                  }
                );
                return;
              } else {
                enqueueSnackbar(i.Status_Message, {
                  variant: 'error',
                });
              }
            }
          });
          setInvalidInputs?.(InvalidInputs);
        }

        setState({
          groupedSandboxRefreshes: map(data, (i) => {
            return {
              ...i,
              GroupName: find(groupsData, { id: i.RowIdentifierID })?.Name,
            };
          }),
        });
        // Update state with data
      };

      await startPolling();
    } catch (e: any) {
      enqueueSnackbar(e.body.message, {
        variant: 'error',
      });
      setState({
        isSandboxChangesDialogLoading: false,
      });
    }
  }, [state.modifiedRowData, state.modifiedChartData, data]);

  const getModifiedRowData = useDynamicCallback((rowData: IModifiedRowData) => {
    const modifiedRowData = flow(
      (data) =>
        reduce(
          data,
          (acc: Record<string, IModifiedRowData[]>, item) => {
            acc[item.Input] = [...(acc[item.Input] || []), item];
            return acc;
          },
          {}
        ),
      (groupedData) => values(groupedData),
      (groups) => map(groups, (group) => last(group))
    )([...state.modifiedRowData, rowData]) as IModifiedRowData[];

    var NewModifiedData = modifiedRowData.map((rowData) => {
      let filterOutLockedData = reject(rowData.Data, (i) =>
        state.lockedGroupNames.includes(i.Group)
      );
      return {
        ...rowData,
        Data: filterOutLockedData,
      };
    });

    setState({
      modifiedRowData: NewModifiedData,
      showOutputsPreviewButton:
        !isEmpty(state.modifiedChartData) &&
        some(NewModifiedData, { isDirty: true }),
    });
    if (!state.isPublishEnabled) {
      setState({
        isPublishEnabled: some(NewModifiedData, { isDirty: true }),
      });
    }
  });

  const resetRowData = useDynamicCallback((inputName: string) => {
    window.sessionStorage.removeItem(
      `gridState-inputName-${inputName}-modified`
    );
    window.sessionStorage.removeItem(
      `filterState-inputName-${inputName}-modified`
    );
    setState({
      isPublishEnabled: false,
      modifiedRowData: reject(state.modifiedRowData, {
        Input: inputName,
      }),
      showOutputsPreviewButton: some(state.modifiedRowData, { isDirty: true }),
    });
  });

  const onConfirmPublishChanges = async () => {
    setState({
      isPublishChangesDialogLoading: true,
      isPublishChangesDialogOpen: false,
    });
    map(state.modifiedRowData, (i, index) => {
      const data = find(state.selectedInputs as ISelectedInputs, {
        Name: i.Input,
      });
      state.modifiedRowData[index].RowIdentifierId = data?.id as Number;
    });
    try {
      const publishData = await API.create(
        WEB_ASSUMPTIONS_PUBLISH(
          data?.modelInstance?.id as number,
          data?.scenario?.id as number
        ),
        {
          Assumptions: state.modifiedRowData,
        }
      );

      const groupsData = await API.load(
        `/instances/${data?.modelInstance?.id}/row_identifiers?type=Group`
      );
      if (isArray(publishData)) {
        setState({
          groupedSubmissions: reduce(
            publishData,
            (acc: State['groupedSubmissions'], c) => {
              acc[c.id] = {
                ...c,
                GroupName: find(groupsData, { id: c.GroupRowIdentifierID })
                  ?.Name,
              };
              return acc;
            },
            {}
          ),
          displaySubmissionsRefreshPanel: true,
        });

        const submissionIds = map(publishData, 'id');
        let InvalidInputs: any[] = [];
        forEach(submissionIds, async (id) => {
          let fetchSubmission = () =>
            API.load(
              `/instances/${modelInstanceId}/scenarios/${scenarioId}/submissions/${id}`
            );
          let validate = (result: Submission) =>
            includes(
              [SubmissionStatus.Started, SubmissionStatus.Queued],
              result.Status
            );
          let response = await poll(fetchSubmission, validate, 2500);
          if (response.Status === SubmissionStatus.Failed) {
            let invalidInputs = [];
            try {
              invalidInputs = response.Status_Message
              ? JSON.parse(response.Status_Message)
              : [];
            } catch (e) {
              console.error('Failed to parse status message:', e);
            }
            if (invalidInputs.length > 0) {
              InvalidInputs = InvalidInputs.concat(invalidInputs);
              setInvalidInputs?.(InvalidInputs);
              enqueueSnackbar(errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE, {
                variant: 'error',
              });
              setState({
                isPublishChangesDialogLoading: false,
                isPublishChangesDialogOpen: false,
                isPublishEnabled: false,
              });
              return;
            } else {
              enqueueSnackbar(response.Status_Message, {
                variant: 'error',
              });
            }
          }
          setState((prevState) => ({
            groupedSubmissions: {
              ...prevState.groupedSubmissions,
              [id]: {
                ...response,
                GroupName: find(groupsData, {
                  id: response.GroupRowIdentifierID,
                })?.Name,
              },
            },
          }));
          forIn(window.sessionStorage, (value: string, objKey: string) => {
            if (startsWith(objKey, 'gridState-inputName-')) {
              window.sessionStorage.removeItem(objKey);
            }
            if (startsWith(objKey, 'filterState-inputName-')) {
              window.sessionStorage.removeItem(objKey);
            }
          });
        });
      }
    } catch (e: any) {
      enqueueSnackbar(e.body.message, {
        variant: 'error',
      });
      setState({
        isPublishChangesDialogLoading: false,
        isPublishChangesDialogOpen: false,
        isPublishEnabled: false,
      });
    }
  };
  const PlaceHolderPanel: FunctionComponent = () => (
    <Flex flex="auto" sx={{ my: 2 }}>
      <Flex flexDirection="column" width={'100%'}>
        <NonIdealState
          action={
            <Button
              sx={{ mx: 'auto' }}
              variant="contained"
              onClick={handleOpenSelectInputModalDialog}
            >
              Select Inputs
            </Button>
          }
          title=" Browse all the input names and select them to edit the
                  assumptions"
          icon={<AssumptionsUploadIcon fontSize="large" />}
          iconFontSize={10}
        />
      </Flex>
    </Flex>
  );

  const refreshData = () => {
    enqueueSnackbar('Refreshing data...', {
      variant: 'info',
    });
    handleSaveSelectInputModalDialog(state.selectedInputs);
  };

  const getTotalGroups = useMemo(
    () => uniqBy(data.submission, 'GroupRowIdentifierID').length || 0,
    [data]
  );

  const mainPanelContent = () => (
    <>
      <Backdrop
        sx={{
          color: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 3,
          backgroundColor: alpha('#596B7A', 0.7),
        }}
        open={state.isPublishChangesDialogLoading}
      >
        <Stack direction="column" spacing={2} alignItems="center">
          {containsOnly(
            [SubmissionStatus.Started, SubmissionStatus.Queued],
            map(state.groupedSubmissions, 'Status')
          ) && <CircularProgress color="primary" />}
          <>
            {!isEmpty(map(state.groupedSubmissions, 'Status')) ? (
              <>
                {containsOnly(
                  [SubmissionStatus.Started, SubmissionStatus.Queued],
                  map(state.groupedSubmissions, 'Status')
                ) ? (
                  <Typography variant="h6">
                    Publish changes are in progress
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    Changes successfully published
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6">
                Publish changes are in progress
              </Typography>
            )}
          </>
          {containsOnly(
            [SubmissionStatus.Started, SubmissionStatus.Queued],
            map(state.groupedSubmissions, 'Status')
          ) && (
            <Typography>
              You may open another session while the current files are being
              processed.
            </Typography>
          )}
          {(() => {
            if (
              containsOnly(
                [SubmissionStatus.Finished],
                map(state.groupedSubmissions, 'Status')
              )
            ) {
              return <Typography>Refreshing...</Typography>;
            } else if (
              containsOnly(
                [SubmissionStatus.Queued, SubmissionStatus.Started],
                map(state.groupedSubmissions, 'Status')
              )
            ) {
              return (
                <Button
                  component={Link}
                  variant="contained"
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/scenario-manager/assumptions?client=${clientId}&model=${modelId}&instance=${modelInstanceId}&scenario=${scenarioId}`}
                >
                  Open New Session
                </Button>
              );
            } else if (
              some(
                map(state.groupedSubmissions, 'Status'),
                (i) => i === SubmissionStatus.Failed
              )
            ) {
              return (
                <Button variant="contained" onClick={refreshData}>
                  Close
                </Button>
              );
            } else if (
              some(
                map(state.groupedSandboxRefreshes, 'Status'),
                (i) => i === SubmissionStatus.NoDataReturned
              )
            ) {
              enqueueSnackbar(
                find(state.groupedSandboxRefreshes, {
                  Status: SubmissionStatus.NoDataReturned,
                })?.Status_Message,
                { variant: 'warning' }
              );
              return (
                <Button
                  variant="contained"
                  onClick={() =>
                    setState({
                      displaySandboxRefreshPanel: false,
                      isSandboxChangesDialogLoading: false,
                      groupedSandboxRefreshes: [],
                    })
                  }
                >
                  Close
                </Button>
              );
            }
          })()}
          {state.displaySubmissionsRefreshPanel &&
            !isEmpty(state.groupedSubmissions) && (
              <FloatingPanelContainer>
                <FloatingPanelHeaderContainer>
                  <FloatingPanelHeaderLayoutContainer>
                    <FloatingPanelHeaderLayoutTextContainer>
                      <Typography sx={{ lineHeight: 'inherit' }}>
                        Refreshing Submissions
                      </Typography>
                    </FloatingPanelHeaderLayoutTextContainer>
                    <Box style={{ flex: '0 0 auto' }}>
                      <FloatingPanelHeaderLayoutTextIconsContainer>
                        <IconButton
                          sx={{
                            height: '40px',
                            width: '40px',
                            margin: '0 4px',
                          }}
                          onClick={() =>
                            setState({
                              isSubmissionsRefreshPanelExpanded:
                                !state.isSubmissionsRefreshPanelExpanded,
                            })
                          }
                        >
                          {state.isSubmissionsRefreshPanelExpanded ? (
                            <ExpandMore sx={{ color: 'white' }} />
                          ) : (
                            <ExpandLess sx={{ color: 'white' }} />
                          )}
                        </IconButton>
                      </FloatingPanelHeaderLayoutTextIconsContainer>
                    </Box>
                  </FloatingPanelHeaderLayoutContainer>
                </FloatingPanelHeaderContainer>
                <Box>
                  <Collapse in={state.isSubmissionsRefreshPanelExpanded}>
                    <Box
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        border: `1px solid #323232`,
                      }}
                    >
                      <List
                        sx={{
                          width: '498px',
                          maxHeight: '300px',
                          overflow: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        {map(state.groupedSubmissions, (i) => {
                          return (
                            <Fragment key={i.id}>
                              <ListItem
                                secondaryAction={
                                  <>
                                    {(() => {
                                      switch (i.Status) {
                                        case SubmissionStatus.Finished:
                                        case SubmissionStatus.NoDataReturned:
                                          return (
                                            <CheckCircle color="success" />
                                          );
                                        case SubmissionStatus.Failed:
                                          return (
                                            <Tooltip
                                              title={
                                                i.Status_Message &&
                                                i.Status_Message.includes(
                                                  'InputRowID'
                                                ) &&
                                                JSON.parse(i.Status_Message)
                                                  ?.length > 0
                                                  ? errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE
                                                  : i.Status_Message
                                              }
                                              arrow
                                            >
                                              <Error color="error" />
                                            </Tooltip>
                                          );
                                        case SubmissionStatus.Started:
                                          return <CircularProgress size={25} />;
                                        case SubmissionStatus.Queued:
                                          return <CircularProgress size={25} />;
                                      }
                                    })()}
                                  </>
                                }
                              >
                                <ListItemText>
                                  <Tooltip
                                    arrow
                                    title={i.GroupName ? i.GroupName : 'Status'}
                                  >
                                    <Typography sx={{ color: 'black' }} noWrap>
                                      {i.GroupName ? i.GroupName : 'Status'}
                                    </Typography>
                                  </Tooltip>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                            </Fragment>
                          );
                        })}
                      </List>
                    </Box>
                  </Collapse>
                </Box>
              </FloatingPanelContainer>
            )}
        </Stack>
      </Backdrop>
      <Backdrop
        sx={{
          color: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 3,
          backgroundColor: alpha('#596B7A', 0.7),
        }}
        open={state.isSandboxChangesDialogLoading}
      >
        <Stack direction="column" spacing={2} alignItems="center">
          {containsOnly(
            [SubmissionStatus.Started, SubmissionStatus.Queued],
            map(state.groupedSubmissions, 'Status')
          ) && <CircularProgress color="primary" />}
          <>
            {!isEmpty(map(state.groupedSandboxRefreshes, 'Status')) ? (
              <>
                {containsOnly(
                  [SubmissionStatus.Started, SubmissionStatus.Queued],
                  map(state.groupedSandboxRefreshes, 'Status')
                ) && (
                  <Typography variant="h6">
                    Sandbox Calculation is in progress
                  </Typography>
                )}
                {containsOnly(
                  [SubmissionStatus.Finished],
                  map(state.groupedSandboxRefreshes, 'Status')
                ) && (
                  <Typography variant="h6">
                    Sandbox Calculation successfully completed
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6">
                Sandbox Calculation is in progress
              </Typography>
            )}
          </>
          {(() => {
            if (
              containsOnly(
                [SubmissionStatus.Finished],
                map(state.groupedSandboxRefreshes, 'Status')
              )
            ) {
              return <Typography>Refreshing...</Typography>;
            } else if (
              some(
                map(state.groupedSandboxRefreshes, 'Status'),
                (i) => i === SubmissionStatus.Failed
              )
            ) {
              return (
                <Button
                  variant="contained"
                  onClick={() => {
                    setState({
                      displaySandboxRefreshPanel: false,
                      groupedSandboxRefreshes: [],
                      isSandboxChangesDialogLoading: false,
                    });
                  }}
                >
                  Close
                </Button>
              );
            } else if (
              some(
                map(state.groupedSandboxRefreshes, 'Status'),
                (i) => i === SubmissionStatus.NoDataReturned
              )
            ) {
              enqueueSnackbar(
                find(state.groupedSandboxRefreshes, {
                  Status: SubmissionStatus.NoDataReturned,
                })?.Status_Message,
                { variant: 'warning' }
              );
              setState({
                displaySandboxRefreshPanel: false,
                groupedSandboxRefreshes: [],
                isSandboxChangesDialogLoading: false,
              });
              return null;
            }
          })()}
          {state.displaySandboxRefreshPanel &&
            !isEmpty(state.groupedSandboxRefreshes) && (
              <FloatingPanelContainer>
                <FloatingPanelHeaderContainer>
                  <FloatingPanelHeaderLayoutContainer>
                    <FloatingPanelHeaderLayoutTextContainer>
                      <Typography sx={{ lineHeight: 'inherit' }}>
                        Refreshing Sandbox Calculation
                      </Typography>
                    </FloatingPanelHeaderLayoutTextContainer>
                    <Box style={{ flex: '0 0 auto' }}>
                      <FloatingPanelHeaderLayoutTextIconsContainer>
                        <IconButton
                          sx={{
                            height: '40px',
                            width: '40px',
                            margin: '0 4px',
                          }}
                          onClick={() =>
                            setState({
                              isSandboxRefreshPanelExpanded:
                                !state.isSandboxRefreshPanelExpanded,
                            })
                          }
                        >
                          {state.isSandboxRefreshPanelExpanded ? (
                            <ExpandMore sx={{ color: 'white' }} />
                          ) : (
                            <ExpandLess sx={{ color: 'white' }} />
                          )}
                        </IconButton>
                      </FloatingPanelHeaderLayoutTextIconsContainer>
                    </Box>
                  </FloatingPanelHeaderLayoutContainer>
                </FloatingPanelHeaderContainer>
                <Box>
                  <Collapse in={state.isSandboxRefreshPanelExpanded}>
                    <Box
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        border: `1px solid #323232`,
                      }}
                    >
                      <List
                        sx={{
                          width: '498px',
                          maxHeight: '300px',
                          overflow: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        {map(state.groupedSandboxRefreshes, (i) => {
                          return (
                            <Fragment key={i.id}>
                              <ListItem
                                secondaryAction={
                                  <>
                                    {(() => {
                                      switch (i.Status) {
                                        case SubmissionStatus.Finished:
                                        case SubmissionStatus.NoDataReturned:
                                          return (
                                            <CheckCircle color="success" />
                                          );
                                        case SubmissionStatus.Failed:
                                          return (
                                            <Tooltip
                                              title={
                                                i.Status_Message &&
                                                i.Status_Message.includes(
                                                  'InputRowID'
                                                ) &&
                                                JSON.parse(i.Status_Message)
                                                  ?.length > 0
                                                  ? errorMessage.DATE_LIKE_INPUT_ERROR_MESSAGE
                                                  : i.Status_Message
                                              }
                                              arrow
                                            >
                                              <Error color="error" />
                                            </Tooltip>
                                          );
                                        case SubmissionStatus.Started:
                                          return <CircularProgress size={25} />;
                                        case SubmissionStatus.Queued:
                                          return <CircularProgress size={25} />;
                                      }
                                    })()}
                                  </>
                                }
                              >
                                <ListItemText>
                                  <Tooltip
                                    arrow
                                    title={i.GroupName ? i.GroupName : 'Status'}
                                  >
                                    <Typography sx={{ color: 'black' }} noWrap>
                                      {i.GroupName ? i.GroupName : 'Status'}
                                    </Typography>
                                  </Tooltip>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                            </Fragment>
                          );
                        })}
                      </List>
                    </Box>
                  </Collapse>
                </Box>
              </FloatingPanelContainer>
            )}
        </Stack>
      </Backdrop>
      <Breadcrumbs crumbs={breadcrumbs} />
      <Container maxWidth={false}>
        <Typography variant="h4">
          Edit Assumptions: {data.scenario?.Name}
        </Typography>
        {isEmpty(state.selectedInputs) ? (
          <PlaceHolderPanel />
        ) : (
          <Box sx={{ my: 2 }}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Tooltip
                  arrow
                  title="Select model inputs to load into the editor below."
                >
                  <Button
                    variant="contained"
                    onClick={handleOpenSelectInputModalDialog}
                  >
                    Select Inputs
                  </Button>
                </Tooltip>
              </Grid>
              <Grid>
                <Stack direction="row" spacing={2}>
                  {!state.showOutputChartPanel &&
                    state.showOutputsChartButton && (
                      <Button
                        variant="outlined"
                        startIcon={<BarChart />}
                        onClick={handleOutputsVisibility}
                      >
                        Open Outputs Panel
                      </Button>
                    )}
                  <Tooltip
                    arrow
                    title={`This button recalculates the forecast using the changed assumption values.
                    For the recalculation to take place, a chart must be generated in the Outputs Panel first.`}
                  >
                    <span>
                      <Button
                        variant="outlined"
                        startIcon={<Calculate />}
                        onClick={handleOutputsPreview}
                        disabled={!state.showOutputsPreviewButton}
                      >
                        Recalculate
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title="This button saves a new active version of the scenario with the changed assumption values you have entered below. The original version will not be overwritten nor deleted."
                    arrow
                  >
                    <span>
                      <Button
                        variant="contained"
                        startIcon={<CloudUpload />}
                        disabled={!state.isPublishEnabled}
                        onClick={() => {
                          if (
                            state.lockedGroupNames.length > 0 &&
                            data.submission &&
                            getTotalGroups === state.lockedGroupNames.length
                          ) {
                            enqueueSnackbar(
                              warnMessage.ALL_GROUPS_LOCKED_ERROR_MESSAGE,
                              {
                                variant: 'warning',
                              }
                            );
                          } else {
                            setState({ isPublishChangesDialogOpen: true });
                          }
                        }}
                      >
                        Publish Changes
                      </Button>
                    </span>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
            <InputTabs
              selectedInputs={state.selectedInputs}
              loading={state.loading}
              selectedTabsData={state.inputsResponse}
              selectedOriginalTabsData={state.originalInputsResponse}
              selectedInputTab={state.selectedInputTab}
              handleChangeTab={handleChangeTab}
              onClose={closeTab}
              getModifiedRowData={getModifiedRowData}
              resetRowData={resetRowData}
              modelInstance={data.modelInstance}
              modifiedRowData={state.modifiedRowData}
              invalidInputs={state.invalidInputs}
            />
          </Box>
        )}
        {state.isSelectInputNamesDialogOpen && (
          <SelectInputsModal
            isOpen={state.isSelectInputNamesDialogOpen}
            onClose={handleCloseSelectInputModalDialog}
            modelInstanceId={modelInstanceId}
            onSave={handleSaveSelectInputModalDialog}
            selectedInputs={state.selectedInputs}
          />
        )}
        <PublishChangesDialog
          open={state.isPublishChangesDialogOpen}
          close={() => setState({ isPublishChangesDialogOpen: false })}
          onConfirm={onConfirmPublishChanges}
          lockedGroups={state.lockedGroupNames}
        />
      </Container>
    </>
  );

  const getModifiedChartData = (data: IOutputsPanelState) => {
    const chartsRefreshedState = data.refreshChart;
    const refreshedCharts = filter(
      keys(chartsRefreshedState),
      (key) => chartsRefreshedState[key]
    );
    setState({
      modifiedChartData: data,
    });
    if (
      !isEmpty(refreshedCharts) &&
      some(map(state.modifiedRowData, 'isDirty'), (i) => i)
    ) {
      setState({
        showOutputsPreviewButton: true,
      });
    }
  };

  useEffect(() => {
    if (every(state.modifiedRowData, (i) => !i.isDirty)) {
      setState({
        showOutputsPreviewButton: false,
        isPublishEnabled: false,
      });
    } else if (some(state.modifiedRowData, { isDirty: true })) {
      setState({
        isPublishEnabled: true,
      });
    }
  }, [state.modifiedRowData]);

  unstable_usePrompt({
    when: some(state.modifiedRowData, { isDirty: true }),
    message:
      'Are you sure you want to exit the Assumption Editor and discard all of your current changes without saving?',
  });

  return (
    <Box sx={{ height: '100vh' }}>
      <EditAssumptionsDetailContext.Provider
        value={{
          assumptionsData: state.modifiedRowData,
          handleAssumptionsDataChange: getModifiedRowData,
          outputsChartData: state.modifiedChartData,
          handleChartDataChange: getModifiedChartData,
          handleOutputsPreview,
          disableRecalculateButton: () =>
            setState({
              showOutputsPreviewButton: false,
            }),
        }}
      >
        <Allotment vertical>
          <Allotment.Pane minSize={100} priority={LayoutPriority.High}>
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                width: '100%',
              }}
            >
              <Container maxWidth={false}>
                <CardContent>{mainPanelContent()}</CardContent>
              </Container>
            </Box>
          </Allotment.Pane>
          <Allotment.Pane visible={state.showOutputChartPanel} minSize={200}>
            <AppBar
              component={Box}
              position="static"
              sx={{ backgroundColor: '#E8EDF3', height: '3.5rem' }}
              className="output-table-header"
            >
              <Box
                sx={{
                  background: (t) => t.palette.primary.main,
                  height: '5px',
                }}
              />
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ ml: 7, mr: 4, height: '3.5rem' }}
              >
                <Grid sm={2}>
                  <Stack
                    direction="row"
                    sx={{ alignItems: 'center' }}
                    spacing={1.5}
                  >
                    <Typography variant="h5">Outputs Panel</Typography>
                    <Tooltip
                      arrow
                      title="The data shown in the chart and table below represent the most recent calculation of the forecast based on changed assumption values. If the forecast has not yet been recalculated, the data represents the original version."
                    >
                      <InfoOutlined color="primary" fontSize="small" />
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid sm={1} container justifyContent="flex-end">
                  {/*<Grid>*/}
                  {/*  <IconButton*/}
                  {/*    component={Link}*/}
                  {/*    target="_blank"*/}
                  {/*    rel="noopener noreferrer"*/}
                  {/*    to={`/scenario-manager/assumptions/outputs/?client=${clientId}&model=${modelId}&instance=${modelInstanceId}&scenario=${scenarioId}`}*/}
                  {/*    onClick={() => {*/}
                  {/*      localStorage.setItem(*/}
                  {/*        'assumptionsData',*/}
                  {/*        JSON.stringify(state.modifiedRowData)*/}
                  {/*      );*/}
                  {/*      localStorage.setItem(*/}
                  {/*        'chartData',*/}
                  {/*        JSON.stringify(state.outputsData)*/}
                  {/*      );*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <OpenInNew />*/}
                  {/*  </IconButton>*/}
                  {/*</Grid>*/}
                  <Grid>
                    <IconButton onClick={handleOutputsVisibility}>
                      <Clear />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </AppBar>
            <Container maxWidth={false}>
              <CardContent>
                <OutputsPanel setInvalidInputs={setInvalidInputs} />
                <Box pt={2} />
              </CardContent>
            </Container>
          </Allotment.Pane>
        </Allotment>
      </EditAssumptionsDetailContext.Provider>
    </Box>
  );
};

export default EditAssumptionsDetail;
