import React, { FunctionComponent } from 'react';

import PopupDialog from '../../shared/PopupDialog';

interface IPublishChangesDialogProps {
  open: boolean;
  close: () => void;
  onConfirm: () => void;
  lockedGroups?: Array<string>;
}

const PublishChangesDialog: FunctionComponent<IPublishChangesDialogProps> = ({
  open,
  close,
  onConfirm,
  lockedGroups,
}) => {
  return (
    <PopupDialog
      open={open}
      close={close}
      title="Publish Changes"
      primaryButtons={[
      {
        id: 'confirmButton',
        label: 'Confirm',
        onClick: onConfirm,
      },
      ]}
    >
      {lockedGroups && lockedGroups.length > 0
      ? <p style={{margin: '0px'}}>The following groups have been locked. If you proceed, a submission will not be made to these groups: <strong>{lockedGroups.join(', ')}</strong>. Please confirm that you would like to proceed</p>
      : 'Please confirm you would like to save your edits as the new active version of the scenario.'
      }
    </PopupDialog>
  );
};

export default PublishChangesDialog;
