import React, { FunctionComponent } from 'react';

import { isEqual, map } from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';

import { ModelInstance, Timescale } from '../../types/models';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ITimescalesMultiSelectProps {
  /**
   * List of timescales to be populated
   */
  timescalesList: Timescale[];
  /**
   * List of timescales selected
   */
  preSelectedTimescales?: Timescale[];
  /**
   * The onChange handler
   * @param timescale
   */
  handleTimescaleChange: (timescale: Timescale[]) => void;
  /**
   * The model instance
   * This component will be disabled until a modelInstance is passed in
   */
  modelInstance?: ModelInstance;
  size?: 'small' | 'medium';
  hasError?: boolean;
}

const TimescalesMultiSelect: FunctionComponent<ITimescalesMultiSelectProps> = ({
  timescalesList,
  preSelectedTimescales,
  handleTimescaleChange,
  modelInstance,
  size = 'medium',
}) => {
  return (
    <Autocomplete
      multiple
      value={preSelectedTimescales}
      onChange={(event, value) => handleTimescaleChange(value)}
      disableCloseOnSelect
      options={timescalesList || []}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option, inputValue, {
          insideWords: true,
          findAllOccurrences: true,
        });
        const parts = parse(option, matches);
        return (
          <Typography component="li" {...props} noWrap>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {map(parts, (part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                  whiteSpace: 'pre',
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        );
      }}
      disabled={modelInstance === undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select timescales"
          fullWidth
          size={size}
        />
      )}
    />
  );
};

export default TimescalesMultiSelect;
