import React, { FormEvent, MouseEvent, PureComponent } from 'react';

import {
  Card,
  Box,
  Radio,
  FormControlLabel,
  ButtonGroup,
  IconButton,
  styled,
  Chip,
} from '@mui/material';
import { ContentCopy, Delete, Edit, IosShare } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import {
  Scenario,
  ScenarioArchiveFlag,
  ScenarioEditState,
} from '../../types/models';
import { isModelOwner, isModelRestricted } from '../../utils/profile';

import { ProfileProps, withProfile } from '../shared/AuthController';

import APICacheContext from '../shared/APICacheContext';

import Flex from '../shared/Flex';

import ArchiveScenarioDialog from './ArchiveScenarioDialog';
import CopyScenarioDialog from './CopyScenarioDialog';
import DeleteScenarioDialog from './DeleteScenarioDialog';
import EditScenarioDialog from './EditScenarioDialog';
import UnarchiveScenarioDialog from './UnarchiveScenarioDialog';

interface Props extends ProfileProps {
  clientId: number;
  modelId: number;
  className?: string;
  selectedScenarioId?: Scenario['id'];

  scenario: Scenario;
  onClick(scenario: Scenario): void;

  onDeleted(scenario: Scenario): void;
  onUnarchived(scenario: Scenario): void;
  onTransferred(scenario: Scenario): void;
  onCopied(scenario: Scenario): void;
  onArchived(scenario: Scenario): void;
}

interface State {
  showArchiveDialog: boolean;
  showCopyDialog: boolean;
  showDeleteDialog: boolean;
  showEditDialog: boolean;
  showUnarchiveDialog: boolean;
}

const INITIAL_STATE = {
  showArchiveDialog: false,
  showCopyDialog: false,
  showDeleteDialog: false,
  showEditDialog: false,
  showUnarchiveDialog: false,
};

class ScenarioCard extends PureComponent<Props, State> {
  public static contextType = APICacheContext;

  public state: State = INITIAL_STATE;
  public render() {
    const { clientId, modelId, selectedScenarioId, scenario, profile } =
      this.props;

    const isOwner = profile !== undefined && isModelOwner(profile, modelId);

    const actionNotAllowed =
      profile !== undefined && isModelRestricted(profile, modelId);

    return (
      <>
        <ArchiveScenarioDialog
          scenario={scenario}
          isOpen={this.state.showArchiveDialog}
          onCancel={this.handleCancelArchive}
          onComplete={this.handleCompleteArchive}
        />
        <EditScenarioDialog
          scenario={scenario}
          isOpen={this.state.showEditDialog}
          onCancel={this.handleCancelEdit}
          onComplete={this.handleCompleteEdit}
        />
        <CopyScenarioDialog
          clientId={clientId}
          modelId={modelId}
          scenario={scenario}
          isOpen={this.state.showCopyDialog}
          onCancel={this.handleCancelCopy}
          onComplete={this.handleCompleteCopy}
        />
        <DeleteScenarioDialog
          scenario={scenario}
          isOpen={this.state.showDeleteDialog}
          onCancel={this.handleCancelDelete}
          onComplete={this.handleCompleteDelete}
        />
        <UnarchiveScenarioDialog
          scenario={scenario}
          isOpen={this.state.showUnarchiveDialog}
          onCancel={this.handleCancelUnarchive}
          onComplete={this.handleCompleteUnarchive}
        />
        <Flex>
          <Box width={'100%'}>
            <Card
              variant="outlined"
              sx={{
                padding: '15px',
                ...(selectedScenarioId !== scenario.id && {
                  width: '95%',
                }),
                ...(selectedScenarioId === scenario.id && {
                  borderRadius: '3px 0 0 3px',
                  backgroundColor: 'rgb(245, 248, 250)',
                  borderRight: 0,
                }),
                cursor: 'pointer',
              }}
              onClick={
                selectedScenarioId !== scenario.id
                  ? () => this.handleClick(scenario)
                  : () => {}
              }
            >
              <Flex alignItems="center">
                <Box mr={2} style={{ minWidth: 0 }}>
                  <FormControlLabel
                    value={scenario.id}
                    control={
                      <Radio
                        checked={scenario.id === selectedScenarioId}
                        onChange={this.handleRadioClick}
                      />
                    }
                    label={scenario.Name}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </Box>
                <Box flex="auto" />
                {scenario.id !== selectedScenarioId &&
                  scenario.TotalLockedSubmissions > 0 && (
                    <>
                      <Chip
                        color="secondary"
                        label={
                          scenario.TotalLockedSubmissions +
                          '/' +
                          scenario.TotalActiveSubmissions +
                          ' GROUPS LOCKED'
                        }
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    </>
                  )}
                {selectedScenarioId === scenario.id && (
                  <>
                    <ButtonGroup sx={{ pr: 2 }}>
                      <ScenarioIcon
                        disabled={actionNotAllowed}
                        title="Edit scenario"
                        onClick={this.handleEditClick}
                      >
                        <Edit />
                      </ScenarioIcon>
                      {scenario.Archive_Flag ===
                        ScenarioArchiveFlag.Current && (
                        <ScenarioIcon
                          disabled={actionNotAllowed}
                          title="Copy scenario"
                          onClick={this.handleCopyClick}
                        >
                          <ContentCopy />
                        </ScenarioIcon>
                      )}
                      <ScenarioIcon
                        disabled={actionNotAllowed}
                        title={
                          scenario.Archive_Flag
                            ? 'Unarchive scenario'
                            : 'Archive scenario'
                        }
                        onClick={
                          scenario.Archive_Flag
                            ? this.handleUnarchiveClick
                            : this.handleArchiveClick
                        }
                      >
                        <IosShare />
                      </ScenarioIcon>
                      <ScenarioIcon
                        disabled={
                          !isOwner ||
                          scenario.Edit_State === ScenarioEditState.Locked ||
                          actionNotAllowed
                        }
                        title="Delete scenario"
                        onClick={this.handleDeleteClick}
                        color="error"
                      >
                        <Delete />
                      </ScenarioIcon>
                    </ButtonGroup>
                  </>
                )}
              </Flex>
            </Card>
          </Box>
        </Flex>
      </>
    );
  }

  private handleRadioClick = (event: FormEvent<HTMLInputElement>) => {
    const scenarioId = event.currentTarget.value;
    const { onClick, scenario } = this.props;
    if (scenarioId) {
      onClick(scenario);
    }
  };

  private handleClick = (selectedScenario: Scenario) => {
    const { onClick } = this.props;
    onClick(selectedScenario);
  };

  private handleArchiveClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showArchiveDialog: true });
  };

  private handleCancelArchive = () => {
    this.setState({ showArchiveDialog: false });
  };

  private handleCompleteArchive = () => {
    const { onArchived, scenario } = this.props;
    this.setState({ showArchiveDialog: false });
    onArchived(scenario);
  };

  private handleCopyClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showCopyDialog: true });
  };

  private handleCancelCopy = () => {
    this.setState({ showCopyDialog: false });
  };

  private handleCompleteCopy = () => {
    const { onCopied, scenario } = this.props;
    this.setState({ showCopyDialog: false });
    onCopied(scenario);
  };

  private handleDeleteClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showDeleteDialog: true });
  };

  private handleCancelDelete = () => {
    this.setState({ showDeleteDialog: false });
  };

  private handleCompleteDelete = () => {
    const { onDeleted, scenario } = this.props;
    this.setState({ showDeleteDialog: false });
    onDeleted(scenario);
  };

  private handleEditClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showEditDialog: true });
  };

  private handleCancelEdit = () => {
    this.setState({ showEditDialog: false });
  };

  private handleCompleteEdit = () => {
    const { onCopied, scenario } = this.props;
    this.setState({ showEditDialog: false });
    onCopied(scenario);
  };

  private handleUnarchiveClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showUnarchiveDialog: true });
  };

  private handleCancelUnarchive = () => {
    this.setState({ showUnarchiveDialog: false });
  };

  private handleCompleteUnarchive = () => {
    const { onUnarchived, scenario } = this.props;
    this.setState({ showUnarchiveDialog: false });
    onUnarchived(scenario);
  };
}

const ScenarioIcon = styled(IconButton)({
  transition: `all 0.2s ease`,
});

export default withProfile(styled(ScenarioCard)`
  &.selected {
    background: ${grey[100]};
  }
`);
